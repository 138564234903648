.login {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  /* margin: 10px auto; */
  min-width: 400px;
  position: relative;
  padding-bottom: 10px;
  margin-top: 148px;
}

.icon {
  width: 70px;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  top: -100px;
}