.activity {
  border: 1px solid #e0e5ea;
  background-color: #fff;
  padding: 24px 24px 24px 136px;
  margin-bottom: 12px;
  position: relative;
  border-radius: 3px;
  /* prevent selection on double click */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.activity:hover {
  border-color: #002B64;
  cursor: pointer;
}

.activity:active {
  border-color: #002B64;
  background-color: #d7e4f5;
}

.activityActive {
  border-color: #002B64;
  background-color: #eef5ff;
}

.timer, .count {
  position: absolute;
  left: 48px;
  width: 72px;
  font-size: 24px;
  text-align: right;
  height: 40px;
  line-height: 40px;
  top: 50%;
  margin-top: -20px;
  white-space: nowrap;
}

.count {
  left: auto;
  right: 15px;
}

.key {
  height: 24px;
  width: 24px;
  border: 1px solid #dbdbdb;
  color: #dbdbdb;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  position: absolute;
  left: 24px;
  margin-top: -12px;
  top: 50%;
  /* font-size: 8px; */
};
