.card {
  margin-bottom: 12px;
}

.cardContent {
  display: flex;
}

.percent {
  padding-right: 24px;
}

.description {
  padding-top: 20px;
}