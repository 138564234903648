.error {
  padding-top: 80px;
  text-align: center;
  font-size: 1.3em;
}

.error .message {
  padding-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 0;
}