.pageHeader {
  border-bottom: 1px solid #e0e5ea;
}

.title {
  font-size: 16px;
}

.extra {
  position: relative;
  top: 1px;
}

.backIcon {
  position: relative;
  top: 1px;
  font-size: 17px;
}