.header {
  padding-left: 15px;
  padding-right: 25px;
  border-bottom: 1px solid #e0e5ea;
}

.logo {
  float: left;
  width: 80px;
  text-align: center;
  position: relative;
  top: -2px;
}

.logoNoUser {
  text-align: center;
}

.logo:hover {
  cursor: pointer;
}

.logoNoUser img,
.logo img {
  height: 32px;
}

.menu {
  list-style-type: none;
}

.menu li {
  float: left;
  height: 80px;
  text-align: center;
}

.menu li a {
  color: #002B64;
  /*color: #000;*/
  text-transform: uppercase;
  display: inline-block;
  height: 80px;
  padding: 0 20px;
}

.menu li a:hover {
  /* text-decoration: underline; */
  color: #000;
}

.menu li.active a span {
  padding: 1px 0;
  border-bottom: 1px solid #3f8cbe;
}

.userSettings {
  margin-top: -16px;
  float: right;
}

.userName {
  /*color: #f8fbfd;*/
  margin-right: 10px;
}