.evaluateContainer {
  display: flex;
  flex:1;
}

.player {
  background: #000;
}

.playerContainer {
  flex: 1;
  padding: 24px;
}

.sidebar {
  width: 380px;
  padding: 24px;
}

.videoPlayer {
  background: #000;
}

.progressContainer {
  position: relative;
}

.timeFrom {
  position: absolute;
  left: 0;
  top: 20px;
}

.timeTo {
  position: absolute;
  right: 0;
  top: 20px;
}

.timeCurrent {
  position: absolute;
  top: 26px;
  border: 1px solid #ccc;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: -6px;
  background: #fff;
}

.timeCurrent:after {
  content: '';
  position: absolute;
  top: -3.5px;
  left: 2px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  border-bottom: 3px solid #fff;
}

.timeCurrent:before {
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #ccc;
}

.videoStartTimeTick,
.videoEndTimeTick,
.videoDelayTimeTick {
  background: #002B64;
  position: absolute;
  top: 4px;
  width: 4px;
  height: 17px;
  border-radius: 1px;
  left: 0;
  cursor: pointer;
}

.videoEndTimeTick {
  left: auto;
  right: 0;
}