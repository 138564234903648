.searchContainer {
  display: flex;
  justify-content: flex-end;
}

.search {
  width: 220px;
  margin-bottom: 16px;
}

.experimentsTable tr:hover {
  cursor: pointer;
}